// import { router } from "../router";
import { auth } from "../firebase";


const guards = {
  is_logged_in: () => {
      if(auth.currentUser !== null)
            return true
       return false
  },
};

export { guards };
