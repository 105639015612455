import { router } from "..";
import { auth } from "../../firebase";

const fieldRoutes = [
  {
    path: "/fields/fields_list",
    name: "Fields",
    beforeEnter: () =>
      auth.currentUser !== null || router.push({ name: "Login" }),
    component: () => import("../../views/fields/Field_list.vue"),
  },
  {
    path: "/fields/new_Field",
    name: "New Field",
    beforeEnter: () =>
      auth.currentUser !== null || router.push({ name: "Login" }),
    component: () => import("../../views/fields/Field_form.vue"),
  },
  {
    path: "/fields/fieldsUpdate/:uuid",
    name: "Field Update",
    beforeEnter: () => auth.currentUser !== null || router.push({name: 'Login'}),
    component: () => import("../../views/fields/Field_update.vue"),
  },
];

export { fieldRoutes };
