import { router } from "../../router";
import { auth } from "../../firebase"

const userRoutes = [{
        path: '/user/profile',
        name: 'UserProfile',
        beforeEnter: () => auth.currentUser !== null || router.push({name: 'Login'}),
        component: () =>
            import ('../../views/User/UserProfile.vue')
    },

]

export { userRoutes }