// import { router } from "..";
// import { auth } from "../../firebase";

const cashflowRoutes = [
  {
    path: "/cashflow/list",
    name: "Cashflows",
    // beforeEnter: () =>
    //   auth.currentUser !== null || router.push({ name: "Login" }),
    component: () => import("../../views/Cashflow/cashflow_list.vue"),
  },
];

export { cashflowRoutes };
