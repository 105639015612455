<template>
  <router-view />
</template>
<script>


export default {

  

  // created() {
  //   // FB.getLoginStatus();
  // },
};
</script>

