// import { router } from "..";
// import { auth } from "../../firebase";

const reportRoutes = [
    {
      path: "/report/export/csv",
      name: "csvExport",
      // beforeEnter: () =>
      //   auth.currentUser !== null || router.push({ name: "Login" }),
      component: () => import("../../views/Reports/csv_uploader_screen.vue"),
    },
  ];
  
  export { reportRoutes };