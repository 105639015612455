const mutations = {
    mutateUserProfile: (state, payload) => {
        state.auth.userProfile = payload
    },
    mutateCredentials: (state, payload) => {
        state.auth.credentials = payload
    },
    mutateUserGroup: (state, payload) => {
        state.auth.userGroup = payload
    },
    mutateUserId: (state, payload) => {
        state.auth.userId = payload
    },
}

export {mutations}