import 'firebase/firestore'
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore"
import { getAuth } from "firebase/auth";
import { getStorage } from 'firebase/storage';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAf4_n0Cbv05lvkEWgh6k2zvQcZacmYAg0",
  authDomain: "accounting-752ea.firebaseapp.com",
  projectId: "accounting-752ea",
  storageBucket: "accounting-752ea.appspot.com",
  messagingSenderId: "881039905300",
  appId: "1:881039905300:web:57faa8713a4f36c1864d01"
};

// Initialize Firebase
initializeApp(firebaseConfig);
export const db = getFirestore();
export const auth = getAuth();
export const storage = getStorage();

