// import { router } from "..";
// import { auth } from "../../firebase";

const bankRoutes = [
    {
      path: "/banks/list",
      name: "Banks",
      // beforeEnter: () =>
      //   auth.currentUser !== null || router.push({ name: "Login" }),
      component: () => import("../../views/Bank/banks_list.vue"),
    },
    {
      path: "/banks/create",
      name: "New Bank",
      // beforeEnter: () =>
      //   auth.currentUser !== null || router.push({ name: "Login" }),
      component: () => import("../../views/Bank/bank_new.vue"),
    },
    {
      path: "/banks/:uuid/cashflow/:status?",
      name: "Bank flow",
      // beforeEnter: () => auth.currentUser !== null || router.push({name: 'Login'}),
      component: () => import("../../views/Bank/bank_management_form.vue"),
    },
    {
        path: "/bank/:uuid/management",
        name: "Bank Management",
        // beforeEnter: () => auth.currentUser !== null || router.push({name: 'Login'}),
        component: () => import("../../views/Bank/bank_management.vue"),
      },
  ];
  
  export { bankRoutes };
  