const getters = {
    getUserProfile: state => {
        return state.auth.userProfile
    },
    getUserId: state => { 
        return state.auth.userId
    },
    isLoggedIn: state => {
        return state.auth.userProfile ? true : false
    },
    getUserGroup:state => {
        return state.auth.userGroup
    }
}

export { getters }