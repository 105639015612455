const authRoutes = [{
        path: '',
        name: 'Login',
        component: () =>
            import ('../views/AuthViews/Login.vue')
    },
    {
        path: 'register',
        name: 'Register',
        component: () =>
            import ('../views/AuthViews/Register.vue')
    },
    {
        path: 'forgot_password',
        name: 'Forgot Password',
        component: () =>
            import ('../views/AuthViews/ForgotPassword.vue')
    },
    {
        path: 'verify_email',
        name: 'Verify Email',
        component: () =>
            import ('../views/AuthViews/VerifyEmail.vue')
    },
    {
        path: 'set_password',
        name: 'Set Password',
        component: () =>
            import ('../views/AuthViews/SetPassword.vue')
    },
    {
        path: 'account_setup/:tkn?',
        name: 'Admin Setup Account',
        component: () =>
            import ('../views/AuthViews/AdminSetupAccount.vue')
    },

]

export { authRoutes }