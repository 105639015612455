import { userRoutes } from "./userRoutes"
import { productRoutes } from "./productRoutes"
import { fieldRoutes } from "./fieldsRoutes";
import { catalogRoutes } from "./catalogRoutes";
import { cashflowRoutes } from "./cashflowRoutes";
import { bankRoutes } from "./bankRoutes";
import { logRoutes } from "./logsRoutes";
import { reportRoutes } from "./reportsRoutes";
// import { router } from "../../router";
// import { auth } from "../../firebase"


const DashboardRoutes = [{
        path: '/dashboard',
        name: 'Dashboard',
        // beforeEnter: () => auth.currentUser !== null || router.push({name: 'Login'}),
        component: () =>
            import ('../../views/DashboardViews/Dashboard.vue'),
    },
    ...userRoutes,
    ...productRoutes,
    ...fieldRoutes,
    ...catalogRoutes,
    ...cashflowRoutes,
    ...bankRoutes,
    ...logRoutes,
    ...reportRoutes,
    {
        path: '/coming_soon',
        name: 'Coming soon',
        component: () =>
            import ('../../views/DashboardViews/ComingSoon.vue'),
    },
    {
        path: '/403',
        name: 'Unauthorized',
        component: () =>
            import ('../../views/DashboardViews/Unauthorized.vue')
    },
]

export { DashboardRoutes }