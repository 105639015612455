// import { router } from "..";
// import { auth } from "../../firebase";

const productRoutes = [
  {
    path: "/products",
    name: "Products",
    // beforeEnter: () =>
    //   auth.currentUser !== null || router.push({ name: "Login" }),
    component: () => import("../../views/products/product_list.vue"),
  },
  {
    path: "/products/new_product",
    name: "New Product",
    // beforeEnter: () =>
    //   auth.currentUser !== null || router.push({ name: "Login" }),
    component: () => import("../../views/products/product_form.vue"),
  },
  {
    path: "/products/productsUpdate/:uuid",
    name: "Product Update",
    // beforeEnter: () => auth.currentUser !== null || router.push({name: 'Login'}),
    component: () => import("../../views/products/product_update.vue"),
  },
  {
    path: "/products/:uuid/management",
    name: "Product Management",
    // beforeEnter: () => auth.currentUser !== null || router.push({name: 'Login'}),
    component: () => import("../../views/products/product_management.vue"),
  },
  {
    path: "/products/:uuid/cashflow/:status?",
    name: "Product CashFlow",
    // beforeEnter: () => auth.currentUser !== null || router.push({name: 'Login'}),
    component: () => import("../../views/products/cashflow_form.vue"),
  },
];

export { productRoutes };
