import moment from "moment";

class DateFormatter {
    static default(date) {
        return moment(date)
    }
    static date_only(date) {
        return moment(date).format('dddd, MMMM Do YYYY')
    }
    static from_now(date) {
        return moment(date).fromNow()
    }
    static fromFirestore(date) {
        const d = date.toDate();
        const day = String(d.getDate()).padStart(2, '0');
        const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = d.getFullYear();
        return `${year}-${month}-${day}`;
    }
    static uploadToFirestore(date){
        return moment(date).format('YYYY-MM-DD')
    }
}

export default DateFormatter