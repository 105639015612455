// import { router } from "..";
// import { auth } from "../../firebase";

const logRoutes = [
    {
      path: "/logs/list",
      name: "logs",
      // beforeEnter: () =>
      //   auth.currentUser !== null || router.push({ name: "Login" }),
      component: () => import("../../views/Logs/logs_list.vue"),
    },
  ];
  
  export { logRoutes };
  