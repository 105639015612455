import axios from "axios";
import { alert } from "../utils/alertUtils";
import { router } from "../router";
import { auth, db } from "../firebase.js";
import { signInWithEmailAndPassword } from "firebase/auth";
import { collection, getDocs, where, query } from "firebase/firestore";
import { signOut } from "firebase/auth";

const actions = {
  login: (context, payload) => {
    signInWithEmailAndPassword(auth, payload.username, payload.password)
      .then(() => {
        const user = auth.currentUser;

        if (user) {
          // User is signed in, see docs for a list of available properties
          // https://firebase.google.com/docs/reference/js/firebase.User
          // ...
          context.dispatch("get_user_profile", user.uid);
        } else {
          // There are no users to be logged in
          alert.error("wrong credentials try, again");
        }
      })
      .catch((error) => {
        // Handle specific errors
        if (error.code === "auth/user-not-found") {
          // User not found error
          alert.error("User not found. try again with another user.");
        } else if (error.code === "auth/wrong-password") {
          // Wrong password error
          alert.error("Wrong password, try again.");
        } else if (error.code == "auth/invalid-email") {
          alert.error("Invalid imail");
        } else {
          // Other errors
          alert.error(error);
        }
      });
  },

  get_user_profile: async (context, user_id) => {
    context.commit("mutateUserId", user_id);

    const usersRef = collection(db, "users");
    const q = query(usersRef, where("user_id", "==", user_id));
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      context.commit("mutateUserGroup", "customer");
    } else {
      context.commit("mutateUserGroup", "superadmin");
    }
    querySnapshot.forEach((doc) => {
      var userGroup;
      if (doc.data().group == "superadmin") {
        userGroup = "superadmin";
      } else if (doc.data().group == "premium") {
        userGroup = "premium";
      } else {
        userGroup = "customer";
      }
      context.commit("mutateUserGroup", userGroup);
      context.commit("mutateCredentials", doc.data());
    });
    router.push({
      name: "Dashboard",
    });
  },
  logout: (context) => {
    context.commit("mutateCredentials", null);
    context.commit("mutateUserProfile", null);
    context.commit("mutateUserId", null);
    context.commit("mutateUserGroup", null);
    signOut(auth)
      .then(() => {
        // Sign-out successful.
        router.push({ name: "Login" });
      })
      .catch((err) => {
        alert.error(err.response || err);
      });
  },

  register: (context, payload) => {
    axios
      .post(`${process.env.VUE_APP_BACKEND_URL}/api/signup/`, payload)
      .then((res) => {
        // alert.success('signedup')
        console.log(res);
        router.push({
          name: "Verify Email",
          query: { email: payload.username },
        });
      })
      .catch((err) => {
        alert.error(err.response || err);
      });
  },
};

export { actions };
