import { router } from "..";
import { auth } from "../../firebase";
const catalogRoutes = [
  {
    path: "/catalogs/new_catalog",
    name: "New Service Catalog",
    beforeEnter: () =>
      auth.currentUser !== null || router.push({ name: "Login" }),
    component: () => import("../../views/serviceCatalog/service_catalog_form.vue"),
  },
  {
    path: "/catalogs/catalog_list",
    name: "Service Catalogs",
    beforeEnter: () =>
      auth.currentUser !== null || router.push({ name: "Login" }),
    component: () => import("../../views/serviceCatalog/service_catalog_list.vue"),
  },

  {
    path: "/catalogs/catalog_detail/:serviceCatalogUuid/:name",
    name: "Service Catalog Detail",
    beforeEnter: () =>
      auth.currentUser !== null || router.push({ name: "Login" }),
    component: () => import("../../views/serviceCatalog/service_catalog_detail.vue"),
  },
];

export { catalogRoutes };
