import { createRouter, createWebHistory } from 'vue-router'
import { authRoutes } from './authRoutes'
import { DashboardRoutes } from './DashboardRoutes'
// import { store } from "../store";

const routes = [


    {
        path: '/',
        name: 'AuthLayout',
        component: () =>
            import ('../Layouts/AuthLayout.vue'),
        children: [
            ...authRoutes
        ]
    },


    {
        path: '/',
        name: 'DashboardLayout',
        component: () =>
            import ('../Layouts/DashboardLayout.vue'),
        // beforeEnter: () => {
        //     if (store.getters.isLoggedIn) {
        //         if (store.getters.getUserProfile.referenceName === 'CUSTOMER') {
        //             router.push('/')
        //         }
        //     } else {
        //         router.push('Login')
        //     }
        // },
        children: [
            ...DashboardRoutes
        ]
    },

    {
        path: '/unauthorized',
        name: 'Unauthorized',
        component: () =>
            import ('../views/AuthViews/Unauthorized.vue'),
    },
]

const router = createRouter({
    // scrollBehavior(to) {
    //     if (to.name != 'IndustryEntitiesPublic') {
    //         return { top: 0, behavior: 'smooth' }
    //     } else if (to.hash) {
    //         return {
    //             el: to.hash,
    //             behavior: 'smooth',
    //         }
    //     }
    // },
    history: createWebHistory(),
    routes
})

export { router }